<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Trestle checker">
      <template #module-nav-logo>
        <img
            v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="dhmeTrestleCheckerStatus === 'success'"
      class="flex-1 overflow-hidden d-flex"
    >
      <panel-resizable
        side="left"
        :min-width="100"
        :default-width="400"
        class="ant-glass-background radius-0 ant-border-right full-height"
      >
        <div class="px-4 py-2 d-flex flex-column overflow-y-auto flex-1">
          <ant-input label="Hall">
            <template #input-field>
              <v-combobox
                v-model="selectedAssemblyHall"
                :items="dhmeTrestleCheckerHalls"
                filled
                clearable
                placeholder="hall"
                hide-details
                item-text="hall"
                return-object
                dense
                single-line
                @click:clear="selectedProject = null"
              />
            </template>
          </ant-input>
          <ant-input label="Project">
            <template #input-field>
              <v-combobox
                v-model="selectedProject"
                :items="assemblyHallProjects"
                filled
                clearable
                :disabled="selectedAssemblyHall === null"
                placeholder="project"
                hide-details
                item-text="name"
                return-object
                dense
                single-line
              />
            </template>
          </ant-input>
          <div
            v-if="selectedProject"
            class="flex-1 overflow-y-auto d-flex flex-column mt-5"
          >
            <v-subheader class="pa-0 mb-2" style="height: auto">
              <div class="text-subtitle-2 py-1">Trestles</div>
            </v-subheader>
            <v-btn-toggle
              v-model="trestleFilter"
              rounded
              dense
              mandatory
              tile
              color="primary"
              group
            >
              <v-tooltip
                v-for="option in trestleFilterOptions"
                :key="option.type"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    {{ option.value }}
                  </v-btn>
                </template>
                <span>{{ option.type }}</span>
              </v-tooltip>
            </v-btn-toggle>
            <div v-if="!trestlesLoading" class="overflow-y-auto pa-2">
              <div
                v-for="(trestle, index) in filteredTrestles"
                :key="index"
                class="d-flex ant-border-bottom pb-2 trestle-list-item px-2"
                @click="selectedTrestle = trestle"
              >
                <div class="d-flex align-center justify-center">
                  {{ index + 1 }}
                </div>
                <div class="d-flex align-center justify-center flex-1">
                  <v-icon
                    v-if="isTrestleProduced(trestle)"
                    color="success"
                    x-large
                    >mdi-check-circle-outline
                  </v-icon>
                </div>
                <base-dhme-single-trestle
                  v-if="selectedAssemblyHall.automated"
                  :type-color-dictionary="typeColorDictionary"
                  :selected-trestle="selectedTrestle"
                  :trestle="trestle"
                />
                <base-dhme-double-trestle
                  v-else
                  :type-color-dictionary="typeColorDictionary"
                  :selected-trestle="selectedTrestle"
                  :trestle="trestle"
                />
              </div>
            </div>
            <div
              v-else-if="selectedProject"
              class="flex-1 d-flex justify-center align-center"
            >
              <ant-loading />
            </div>
          </div>
        </div>
      </panel-resizable>
      <div v-if="selectedTrestle" class="flex-1 d-flex">
        <div
          class="ant-glass-background radius-0 ant-border-right py-4 px-10 overflow-y-auto"
        >
          <v-subheader class="pa-0 mb-2" style="height: auto">
            <div class="text-subtitle-2 py-1">Trestle</div>
          </v-subheader>
          <base-dhme-single-trestle
            :trestle="selectedTrestle"
            :type-color-dictionary="typeColorDictionary"
            :selected-module="highlightedModule"
            :is-large="true"
            @clickedElement="selectElementToScan"
          />
          <v-subheader class="pa-0 mb-2 mt-5" style="height: auto">
            <div class="text-subtitle-2 py-1">Modules</div>
          </v-subheader>
          <div class="d-flex flex-column">
            <v-tooltip
              v-for="module in [
                ...new Set(
                  selectedTrestle.positions
                    .flatMap((p) => p.elements)
                    .map((e) => e.module_id)
                ),
              ]"
              :key="module"
              left
            >
              <template #activator="{ on }">
                <v-chip
                  small
                  :color="getColorByModuleId(module)"
                  class="mb-1"
                  :style="{
                    opacity:
                      highlightedModule !== null
                        ? highlightedModule === module
                          ? 1
                          : 0.3
                        : 1,
                  }"
                  v-on="on"
                  @mouseenter="highlightedModule = module"
                  @mouseleave="highlightedModule = null"
                  >{{ module }}</v-chip
                >
              </template>
              <span>{{ getTypeByModuleId(module) }}</span>
            </v-tooltip>
          </div>
        </div>
        <v-dialog
          key="location-scanner"
          :value="scannedElement !== null"
          max-width="500px"
          @click:outside="
            scannedElement = null;
            highlightedModule = null;
          "
          @keydown.esc="
            scannedElement = null;
            highlightedModule = null;
          "
        >
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              Scan {{ scannedElement?.element_type }}
            </v-card-title>
            <v-card-text>
              <base-ant-scanner @decodedResult="validateElement" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div v-else class="flex-1 d-flex justify-center align-center">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import { mapGetters } from 'vuex';
import { DHME_M_TRESTLE_CHECKER } from '@/modules/modules';
import AntLoading from '@/components/AntLoading.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import AntInput from '@/components/AntInput.vue';
import moment from 'moment';
import ColorHelper from '@/services/color-helper';
import BaseAntScanner from '@/components/Base/BaseAntScanner.vue';
import BaseDhmeSingleTrestle from '@/components/Modules/Daiwa-House-Modular-Europe/Base/BaseDhmeSingleTrestle.vue';
import BaseDhmeDoubleTrestle from '@/components/Modules/Daiwa-House-Modular-Europe/Base/BaseDhmeDoubleTrestle.vue';
import { generateAutomatedVirtualTrestlesByPosition } from '@/services/dhme/trestles-helper';

export default {
  name: 'DHMEMTrestleChecker',
  components: {
    BaseDhmeDoubleTrestle,
    BaseDhmeSingleTrestle,
    BaseAntScanner,
    AntInput,
    AntLoading,
    PanelResizable,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      selectedAssemblyHall: null,
      selectedProject: null,
      assemblyHallProjects: [],
      trestleFilterOptions: [
        {
          type: 'Header walls (north)',
          value: 'N',
        },
        {
          type: 'Header walls (south)',
          value: 'S',
        },
        {
          type: 'Side walls (west)',
          value: 'W',
        },
        {
          type: 'Side walls (east)',
          value: 'E',
        },
        {
          type: 'Inside walls',
          value: 'I',
        },
        {
          type: 'Roofs',
          value: 'R',
        },
      ],
      trestleOptions: [
        {
          type: 'Header walls (north)',
          position: ['1'],
          trestles: [],
        },
        {
          type: 'Header walls (south)',
          position: ['2'],
          trestles: [],
        },
        {
          type: 'Side walls (west)',
          position: ['3'],
          trestles: [],
        },
        {
          type: 'Side walls (east)',
          position: ['4'],
          trestles: [],
        },
        {
          type: 'Inside walls',
          position: ['5'],
          trestles: [],
        },
        {
          type: 'Roofs',
          position: ['6'],
          trestles: [],
        },
      ],
      isLoading: false,
      trestlesLoading: false,
      modules: [],
      trestles: [],
      typeColorDictionary: {},
      selectedTrestle: null,
      scannedElement: null,
      projectTrestles: [],
      highlightedModule: null,
      assemblyLanes: [],
      assemblyLocations: [],
      trestleFilter: 0,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeTrestleCheckerStatus',
      'dhmeTrestleCheckerHalls',
        'isDaiwaLicense'
    ]),

    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_TRESTLE_CHECKER
      ).id;
    },

    filteredTrestles() {
      const filter = this.trestleFilterOptions[this.trestleFilter];

      return this.trestleOptions.find((o) => o.type === filter.type).trestles;
    },
  },
  watch: {
    async selectedAssemblyHall(value) {
      if (value) {
        this.selectedProject = null;
        this.selectedTrestle = null;
        this.projectTrestles = [];

        this.assemblyHallProjects = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchHallProjects',
          {
            hall: value.id,
          }
        );
      }
    },
    async selectedProject(value) {
      if (value) {
        this.trestlesLoading = true;
        this.selectedTrestle = null;
        this.projectTrestles = [];
        this.modules = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchModulesForProject',
          {
            project: value.id,
          }
        );
        if (!this.selectedAssemblyHall.automated) {
          this.assemblyLocations = await executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'fetchHallLocations',
            {
              hall: value.id,
              project: value.id,
            }
          );

          let lanes = [
            ...new Set(
              this.assemblyLocations.map((location) => location.assembly_lane)
            ),
          ].sort((a, b) => a - b);

          lanes.forEach((lane) => {
            this.assemblyLanes.push({
              type: lane,
              trestles: [],
              tmpTrestle: {
                positivePositions: [],
                negativePositions: [],
              },
            });
          });
        }
        this.createTypeColorDictionary();
        if (this.selectedAssemblyHall.automated) {
          this.createAutomatedVirtualTrestles();
        } else {
          this.createManualVirtualTrestles();
        }
        await this.getProjectTrestles();
        this.trestlesLoading = false;
      }
    },
    selectedTrestle(value) {
      this.scannedElement = null;
    },
  },
  mounted() {
    this.$store.dispatch('fetchDhmeTrestleCheckerData', {
      moduleId: this.moduleId,
      sessionId: this.$route.params.sessionId ?? null,
    });
  },
  methods: {
    isTrestleProduced(trestleItem) {
      return (
        this.projectTrestles.findIndex(
          (projectTrestle) =>
            projectTrestle.first_element ===
            trestleItem.positions.filter((position) => !position.isEmpty)[0]
              ?.element?.element_id
        ) !== -1
      );
    },
    async getProjectTrestles() {
      this.projectTrestles = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getProjectTrestles',
        {
          project_id: this.selectedProject.id,
        }
      );
    },
    selectElementToScan(element) {
      if (element.production_id === null) {
        this.scannedElement = element;
        this.highlightedModule = element.module_id;
      } else {
        this.$store.commit('showNotification', {
          content: 'This element is already scanned',
          color: 'warning',
        });
      }
    },
    getColorByModuleId(moduleId) {
      let elements = this.selectedTrestle.positions
        .flatMap((p) => p.elements)
        .filter((e) => e.module_id === moduleId);

      if (elements.length > 0) {
        let moduleType = elements[0].module_type;
        return this.typeColorDictionary[moduleType];
      }
      return '';
    },
    getTypeByModuleId(moduleId) {
      let elements = this.selectedTrestle.positions
        .flatMap((p) => p.elements)
        .filter((e) => e.module_id === moduleId);

      if (elements.length > 0) {
        return elements[0].module_type;
      }
      return '';
    },
    createTypeColorDictionary() {
      let types = [
        ...new Set(
          this.modules
            .flatMap((module) => module.elements)
            .map((element) => element.module_type)
        ),
      ];
      types.forEach((type) => {
        this.typeColorDictionary[type] = ColorHelper.generateLightColorHex();
      });
    },
    createManualVirtualTrestles() {
      this.assemblyLanes.forEach((lane) => {
        lane.trestles = [];
        lane.tmpTrestle = {
          positions: [],
        };
        let modules = this.modules
          .filter(
            (module) =>
              module.elements.length > 0 &&
              this.assemblyLocations
                .filter((location) => location.assembly_lane === lane.type)
                .some((location) => location.id === module.assembly_location)
          )
          .sort(
            (a, b) =>
              moment(a.planned_start).isAfter(moment(b.planned_start)) ||
              this.assemblyLocations.find(
                (location) => location.id === a.assembly_location
              ).assembly_location -
                this.assemblyLocations.find(
                  (location) => location.id === b.assembly_location
                ).assembly_location
          )
          .reverse();

        modules.forEach((module, index) => {
          if (module.elements.length > 0) {
            if (
              module.elements.length <=
              10 - lane.tmpTrestle.positions.length
            ) {
              module.elements.forEach((element) => {
                if (lane.tmpTrestle.positions.length < 10) {
                  if (lane.tmpTrestle.positions.length <= 5) {
                    lane.tmpTrestle.positions.push({
                      isEmpty: false,
                      element,
                    });
                  } else {
                    lane.tmpTrestle.positions.splice(5, 0, {
                      isEmpty: false,
                      element,
                    });
                  }
                } else {
                  lane.trestles.push({ ...lane.tmpTrestle });
                  lane.tmpTrestle.positions = [];
                }
              });
            } else {
              lane.tmpTrestle = this.addEmptySpacesToTrestle(lane.tmpTrestle);

              lane.trestles.push({ ...lane.tmpTrestle });
              lane.tmpTrestle.positions = [];

              module.elements.forEach((element) => {
                if (lane.tmpTrestle.positions.length < 10) {
                  if (lane.tmpTrestle.positions.length <= 5) {
                    lane.tmpTrestle.positions.push({
                      isEmpty: false,
                      element,
                    });
                  } else {
                    lane.tmpTrestle.positions.splice(5, 0, {
                      isEmpty: false,
                      element,
                    });
                  }
                } else {
                  lane.trestles.push({ ...lane.tmpTrestle });
                  lane.tmpTrestle.positions = [];
                }
              });
            }

            if (lane.tmpTrestle.positions.length === 10) {
              lane.trestles.push({ ...lane.tmpTrestle });
              lane.tmpTrestle.positions = [];
            } else {
              if (index === modules.length - 1) {
                lane.tmpTrestle = this.addEmptySpacesToTrestle(lane.tmpTrestle);
                lane.trestles.push({ ...lane.tmpTrestle });
                lane.tmpTrestle.positions = [];
              }
            }
          }
        });
      });

      this.trestles = this.assemblyLanes
        .flatMap((option) => option.trestles)
        .sort((a, b) =>
          moment(a.positions[0].module?.planned_start).diff(
            moment(b.positions[0].module?.planned_start)
          )
        );
    },
    addEmptySpacesToTrestle(trestle) {
      while (trestle.positions.length < 10) {
        if (trestle.positions.length <= 5) {
          trestle.positions.unshift({
            isEmpty: true,
            element: null,
          });
        }
        trestle.positions.push({
          isEmpty: true,
          element: null,
        });
      }
      return trestle;
    },
    createAutomatedVirtualTrestles() {
      this.trestleOptions = [
        {
          type: 'Header walls (north)',
          position: ['1'],
          trestles: [],
        },
        {
          type: 'Header walls (south)',
          position: ['2'],
          trestles: [],
        },
        {
          type: 'Side walls (west)',
          position: ['3'],
          trestles: [],
        },
        {
          type: 'Side walls (east)',
          position: ['4'],
          trestles: [],
        },
        {
          type: 'Inside walls',
          position: ['5'],
          trestles: [],
        },
        {
          type: 'Roofs',
          position: ['6'],
          trestles: [],
        },
      ];

      this.trestleOptions.forEach((option) => {
        option.trestles = generateAutomatedVirtualTrestlesByPosition(
          this.modules,
          option.position
        );
      });
    },
    async validateElement(scannedResult) {
      executeCustomModuleCall(this.project.id, this.moduleId, 'scanElement', {
        project_id: this.selectedProject.id,
        element_id: this.scannedElement.element_id,
        scanned_url: scannedResult,
      })
        .then((response) => {
          this.scannedElement.production_id = response;
          this.scannedElement = null;

          this.checkTrestleComplete();
        })
        .catch((error) => {
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    },
    checkTrestleComplete() {
      if (
        this.selectedTrestle.positions.filter(
          (position) => !position.isEmpty && !position.element?.production_id
        ).length === 0
      ) {
        executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'createTrestle',
          {
            project_id: this.selectedProject.id,
            element_id: this.selectedTrestle.positions.filter(
              (position) => !position.isEmpty
            )[0].element.element_id,
          }
        )
          .then((response) => {
            this.projectTrestles.push(response);
          })
          .catch((error) => {
            this.$store.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.trestle-list-item {
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background-color: #f2f2f2;
  }
}

::v-deep .v-dialog {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
